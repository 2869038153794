@charset "utf-8";

// font-awsome path
$fa-font-path:        "../fonts/font-awesome";

// Our variables
$base-font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
$base-font-size:   12pt;
$small-font-size:  $base-font-size * 0.875;
$smaller-font-size:   $base-font-size * 0.75;
$base-line-height: 1.5;

$mono-font-family: 'Source Code Pro', Menlo, Monaco, Inconsolata;

$text-color:       hsl(0, 0%, 25%);

$alt-color:        hsl(0, 70%, 46%);
$alt-color-darker: darken($alt-color, 6%);

$article-bg-color: hsl(35, 36%, 95%);
$background-color: darken($article-bg-color, 6%);
$bg-color-darker:  darken($article-bg-color, 15%);
$alt-text-color:   darken($article-bg-color, 60%);

$subdued-border:   rgba($bg-color-darker, 0.5);

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "base",
  "syntax-highlighting",
  "font-awesome/font-awesome"
;
